<template>
    <el-row>
        <h1>convert</h1>
        <el-row class="contents">
            <el-row>
                <h2>テキストからhexに変換する</h2>
            </el-row>
            <el-row>
                <el-col>
                    const hexText = Buffer.from('<input v-model="s1_text">', 'utf8').toString('hex');
                </el-col>
            </el-row>
            <el-row>
                <h4>変換結果</h4>
            </el-row>
            <el-row>
                <el-col>
            <textarea
                    :rows="2"
                    :span="8"
                    placeholder="Please input"
                    v-model="s1_hex"></textarea>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h2>HexテキストからUTF8に変換する</h2></el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span>const wordArray = CryptoJS.enc.Hex.parse(<input v-model="s1_hex"/>)</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span>const utf8data = CryptoJS.enc.Utf8.stringify(words);</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h4>変換結果結果</h4></el-col>
            </el-row>
            <el-row>
                <el-col>
                  <textarea
                          :rows="2"
                          placeholder="Please input"
                          v-model="s3_utf8">
                  </textarea>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h2>テキストからBase64に変換する(CryptoJS)</h2></el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span>const wordArray = CryptoJS.enc.Base64.parse(<input v-model="s4_text"/>)</span>
                </el-col>
            </el-row>
            <el-row>
                <h4>変換結果</h4>
            </el-row>
            <el-row>
                <el-col>
            <textarea
                    :rows="2"
                    :span="8"
                    placeholder="Please input"
                    v-model="s4_base64"></textarea>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h2>Base64からUTF-8に変換する(CryptoJS)</h2></el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span>const wordArray2 = CryptoJS.enc.Base64.parse(<input v-model="s4_base64"/>)</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h4>変換結果結果</h4></el-col>
            </el-row>
            <el-row>
                <el-col>
                  <textarea
                          :rows="2"
                          placeholder="Please input"
                          v-model="s5_utf8">
                  </textarea>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h2>テキストからBase64に変換する(encodeURIComponent)</h2></el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span>const base64 = btoa(encodeURIComponent(<input v-model="s6_text"/>))</span>
                </el-col>
            </el-row>
            <el-row>
                <h4>変換結果</h4>
            </el-row>
            <el-row>
                <el-col>
            <textarea
                    :rows="2"
                    :span="8"
                    placeholder="Please input"
                    v-model="s6_base64"></textarea>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h2>Base64からUTF-8に変換する(decodeURIComponent)</h2></el-col>
            </el-row>
            <el-row>
                <el-col>
                    <span>const utf8 = decodeURIComponent(atob(<input v-model="s6_base64"/>))</span>
                </el-col>
            </el-row>
            <el-row>
                <el-col><h4>変換結果結果</h4></el-col>
            </el-row>
            <el-row>
                <el-col>
                  <textarea
                          :rows="2"
                          placeholder="Please input"
                          v-model="s6_utf8">
                  </textarea>
                </el-col>
            </el-row>
        </el-row>
    </el-row>
</template>
<script>
    import { Buffer } from "buffer";
    const CryptoJS = require("crypto-js")
    export default {
        name: "convert",
        data() {
            return {
                s1_text: "",
                s1_hex: "",
                s2_text: "",
                s2_hex: "",
                s3_utf8:"",
                s4_text:"",
                s4_base64:"",
                s5_utf8:"",
                s6_text:"",
                s6_base64:"",
                s6_utf8:""
            };
        },
        computed: {},
        methods: {},
        watch: {
            s1_text: function (new_val, old_val) {
                if (new_val === old_val) return
                this.s1_hex = Buffer.from(new_val, 'utf8').toString('hex')
            },
            s1_hex: function (new_val, old_val) {
                if (new_val === old_val) return
                this.s2_hex = CryptoJS.enc.Hex.parse(new_val)
                this.s3_utf8 = CryptoJS.enc.Utf8.stringify(this.s2_hex);
            },
            s4_text: function (new_val, old_val) {
                if (new_val === old_val) return
                let words = CryptoJS.enc.Utf8.parse(new_val.toString());
                this.s4_base64 = CryptoJS.enc.Base64.stringify(words);
            },
            s4_base64: function (new_val, old_val) {
                if (new_val === old_val) return
                var words = CryptoJS.enc.Base64.parse(new_val.toString());
                this.s5_utf8 = CryptoJS.enc.Utf8.stringify(words);
            },
            s6_text: function (new_val, old_val) {
                if (new_val === old_val) return
                this.s6_base64 = btoa(encodeURIComponent(new_val))
            },
            s6_base64: function(new_val,old_val){
                if (new_val === old_val) return
                this.s6_utf8 = decodeURIComponent(atob(new_val));
            }
        }
    };
</script>
<style>
    textarea {
        width:80%
    }
</style>