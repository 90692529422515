import Vue from 'vue'
import VueRouter from 'vue-router';
import store from './store';
import App from "./App.vue"
import ElementUI from 'element-ui' //追加
import locale from 'element-ui/lib/locale/lang/ja' //追加
import 'element-ui/lib/theme-chalk/index.css'
import router from "./router"; //追加

Vue.config.productionTip = false
window.Vue = Vue;
Vue.use(ElementUI, {locale}) //追加
Vue.use(VueRouter)

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')

