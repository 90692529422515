<template>
    <div>
        <hr>
        <div v-if="this.$route.name === 'displayPage'">
        </div>
        <div v-else>
            <div><h5>0x2b.space 2023 All rights reserved.</h5></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "GlobalFooter"
    }
</script>