<template>
  <div>
    <el-row>
      <el-col>
        <span class="txt-year">{{ year }}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <span class="txt-month">{{ month }}</span>
        <span class="txt-month">/</span>
        <span class="txt-date">{{ date }}</span>
        <span class="txt-dayofweek">[{{ dayOfWeek }}]</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <span class="txt-hour">{{ hour }}</span>
        <span class="txt-hour">:</span>
        <span class="txt-min">{{ min }}</span>
        <span class="txt-hour">:</span>
        <span class="txt-sec">{{ sec }}</span>
        <span class="txt-colon">.</span><span class="txt-mil">{{ mil }}</span>
      </el-col>
    </el-row>
    <el-row>
      <span>Offset:</span>
      <el-select
          v-model="selectedOption"
          filterable
      >
        <el-option
            v-for="option in options"
            :key="option.id"
            :label="option.label"
            :value="option.id"
        ></el-option>
      </el-select>
    </el-row>
  </div>
</template>
<script setup>

</script>
<script>
export default {
  name: "Clock",
  data() {
    return {
      year: 2023,
      month: "",
      date: 1,
      dayOfWeek: "Sun",
      hour: 0,
      min: 0,
      sec: 0,
      mil: 0,
      nano: 0,
      selectedOption: null,
      options: [],
    }
  },
  mounted() {
    const daysOfWeekShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    // タイマーを使って1秒ごとに時間を更新する
    setInterval(() => {
      const t = new Date();
      const time = new Date(t.getUTCFullYear(), t.getUTCMonth(), t.getUTCDate(), t.getUTCHours(), t.getUTCMinutes(), t.getUTCSeconds(), t.getUTCMilliseconds());
      if (this.selectedOption !== null) {
        const selectedOffset = this.options.find(option => option.id === this.selectedOption).offset;
        time.setHours(time.getHours() + selectedOffset);
      }
      this.year = time.getFullYear();
      this.month = String(time.getMonth()+1).padStart(2, '0');
      this.date = String(time.getDate()).padStart(2, '0');
      this.dayOfWeek = daysOfWeekShort[time.getDay()];
      this.hour = String(time.getHours()).padStart(2, '0');
      this.min = String(time.getMinutes()).padStart(2, '0');
      this.sec = String(time.getSeconds()).padStart(2, '0');
      this.mil = String(time.getMilliseconds()).padStart(3, '0');
    }, 1);
  },
  watch: {
    options: {
      handler() {
        this.updateFormattedDate();
      },
      deep: true,
    },
  },
  created() {
    this.generateOptions();
    this.updateFormattedDate();
  },
  methods: {
    generateOptions() {
      for (let offset = -12; offset <= 12; offset++) {
        const id = offset; // オフセットをidとして使用
        const label = offset >= 0 ? `UTC+${offset}` : `UTC${offset}`;
        this.options.push({id, label, offset});
      }
      this.selectedOption = 9
    },
    updateFormattedDate() {

    }
  }
}
</script>

<style scoped>
span {
  font-family: "Courier New", monospace;
}

.txt-year {
  font-size: 3rem;
}

.txt-month {
  font-size: 10rem;
}

.txt-date {
  font-size: 10rem;
}

.txt-dayofweek {
  font-size: 5rem;
}

.txt-hour {
  font-size: 7rem;
}

.txt-min {
  font-size: 7rem;
}

.txt-colon {
  font-size: 5rem;
}

.txt-sec {
  font-size: 7rem;
}

.txt-mil {
  font-size: 5rem;
}

</style>
