<template>
    <div v-if="this.$route.name === 'displayPage'">
    </div>
    <div v-else>
        <h2><span>PLUS</span></h2>
        <div>
          <router-link to="/">ROOT</router-link>|
          <router-link to="/convert">CONVERT</router-link>|
          <router-link to="/clock">CLOCK</router-link>
        </div>
        <hr>
    </div>
</template>
<script>
    export default {
        name: "GlobalHeader",
        components: {
        }
    }
</script>