import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './components/Home.vue';
import ConvertPage from  './components/ConvertPage.vue';
import ClockPage from './components/ClockPage.vue';
Vue.use(VueRouter)

export default new VueRouter({
    mode:'history',
    routes:[
        {
            path:'/',
            name:'Home',
            component:Home
        },
        {
            path:'/convert',
            name:'convert',
            component:ConvertPage
        },
        {
            path:'/clock',
            name:'clock',
            component:ClockPage
        }
    ]
})